import isEmpty from 'lodash/isEmpty';

import searchOrderItems from 'Helpers/Order/OrderSearchHelper';

const FETCH_ORDERS = 'frontend/orders/FETCH_ORDERS';
const CHANGE_PAGE = 'frontend/orders/CHANGE_PAGE';
const CHANGE_SEARCH_TERM = 'frontend/orders/CHANGE_SEARCH_TERM';
const ORIGIN_LINK = 'frontend/orders/ORIGIN_LINK';
// const ORIGIN_LINK_STATUS = 'frontend/orders/ORIGIN_LINK_STATUS';
const SELECTED_ORDER_ID = 'frontend/orders/SELECTED_ORDER_ID';
const CLEAR_ORDERS = 'frontend/orders/CLEAR_ORDERS';

const defaultState = { all: [] };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_ORDERS:
      return { ...state, all: action.payload };
    case CHANGE_PAGE:
      return { ...state, currentPage: action.payload };
    case SELECTED_ORDER_ID:
      return { ...state, selectedOrder: action.payload };
    case CHANGE_SEARCH_TERM: {
      let orders = [];
      const searchTerm = action.payload;

      if (!isEmpty(state.all) && !isEmpty(searchTerm)) {
        orders = searchOrderItems(state.all, action.payload);
      }

      return { ...state, searchTerm: action.payload, searchResults: orders };
    }
    case ORIGIN_LINK:
      return state;
    case CLEAR_ORDERS:
      return defaultState;
    default:
      return state;
  }
}

import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import times from 'lodash/times';
import get from 'lodash/get';
import floor from 'lodash/floor';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import round from 'lodash/round';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import ceil from 'lodash/ceil';

import env from 'Env';
import countryData from 'Helpers/Geo/data';

const cartSelector = state => state.cart;
const pickAndMixAllSelector = state => state.pickAndMix.all;
const voucherSelector = state => state.voucher;

const checkoutError = (type, status, message, data) => {
  const returnData = {
    type: type || 'checkout',
    status: status || 'UNKNOWN_ERROR',
    message: message || 'Failed to Create Order',
    data: data || {},
  };
  return returnData;
};

const isStarDeal = item => get(item, 'current_discount.star_deal');

const extractFromPriceObject = (price, currencyCode) => {
  if (!price) {
    return false;
  }
  return price[currencyCode];
};

const convertObjectToInt = (pence, currencyCode) => {
  let newPence = pence;
  if (newPence instanceof Object) {
    newPence = newPence[currencyCode];
  }
  return floor(newPence);
};

const penceToPounds = (pence) => {
  const divPence = pence / 100;
  const roundPence = round(divPence, 2);
  return roundPence;
};

const discountPriceCalc = (item, currencyCode) => {
  const price = item.price[currencyCode];

  if (item.type === 'bundle' && !item.is_srp_bundle) {
    return convertObjectToInt(price, currencyCode);
  }

  if (!item.current_discount || !item.current_discount.percent) {
    return convertObjectToInt(price, currencyCode);
  }

  return convertObjectToInt(
    price * (1 - item.current_discount.percent),
    currencyCode,
  );
};

const tierPricing = (tierArray, currencyCode) => {
  let tierPrice = 0;
  tierArray.forEach((tier) => {
    tier.games.forEach((game) => {
      tierPrice += convertObjectToInt(game.price, currencyCode);
    });
  });
  return tierPrice;
};

/**
 * Determine which coupons in basket are applicable to the coupon
 * @param  {Array.Object}  cartItems          Array of products
 * @return {Object}
 *   @return {Object.Array}  [voucherApplyArray=[]] Array of included products
 *   @return {Object.Array}  [voucherExcludes=[]] Array of excluded products
 */
export function itemsToApplyVoucherTo(voucherCheckObject) {
  const {
    voucher,
    pickAndMixAll,
    cartItems,
  } = voucherCheckObject;
  const isPickAndMix = (item) => {
    let found = false;

    if (Array.isArray(pickAndMixAll) && pickAndMixAll.length > 0) {
      pickAndMixAll.forEach((pickAndMix) => {
        const products = get(pickAndMix, 'products', []);
        products.forEach((product) => {
          if (item._id === product._id) {
            found = true;
          }
        });
      });
    }

    return found;
  };

  // Required / Excluded products / suppliers

  let includedItems = cloneDeep(cartItems);

  includedItems.forEach((product) => {
    if (product.release_date) {
      const today = new Date().getTime();
      const fourWeeksAgo = new Date().getTime() - (1000 * 60 * 60 * 24 * 28);
      const releaseTime = new Date(product.release_date).getTime();

      if (releaseTime > today) {
        product.preorder = true; // eslint-disable-line no-param-reassign
      } else if (releaseTime > fourWeeksAgo) {
        product.newRelease = true;
      }
    }
  });

  if (voucher.excluded_products && voucher.excluded_products.length) {
    includedItems = includedItems.filter(
      // products may be objects or just ids, so we check both
      item => !voucher.excluded_products.map(product => product._id || product).includes(item._id),
    );
  }

  if (voucher.required_products && voucher.required_products.length) {
    includedItems = includedItems.filter(
      // products may be objects or just ids, so we check both
      item => voucher.required_products.map(product => product._id || product).includes(item._id),
    );

    const voucherApplyIds = includedItems.map(voucherApp => voucherApp._id);
    const cartIds = cartItems.map(item => item._id);

    const voucherExcludes = difference(cartIds, voucherApplyIds);

    return {
      voucherApplyArray: includedItems,
      voucherExcludes,
    };
  }

  const excludedSuppliers = voucher.excluded_suppliers || [];

  if (excludedSuppliers && excludedSuppliers.length) {
    includedItems = includedItems.filter(
      item => !excludedSuppliers.includes(get(item, 'supplier_id', '')),
    );
  }

  if (voucher.required_suppliers && voucher.required_suppliers.length) {
    includedItems = includedItems.filter(
      item => voucher.required_suppliers.includes(get(item, 'supplier_id', '')),
    );
  }

  if (voucher.excluded_facets && voucher.excluded_facets.length) {
    includedItems = includedItems.filter(
      item => env.facetsForVouchers.every((facet) => {
        const arr = item[facet] || [];

        return !arr.some(facetValue => voucher.excluded_facets.includes(facetValue));
      }),
    );
  }

  if (voucher.required_facets && voucher.required_facets.length) {
    includedItems = includedItems.filter(
      item => env.facetsForVouchers.some((facet) => {
        const arr = item[facet] || [];

        return arr.some(facetValue => voucher.required_facets.includes(facetValue));
      }),
    );
  }

  includedItems = includedItems.filter(item => isEmpty(item.pay_what_you_want));

  if (voucher.full_price_only) {
    includedItems = includedItems.filter(
      item => !get(item, 'current_discount.percent', 0),
    );
  }

  // Exclude preorders and new releases if not selected
  if (!voucher.preorder) {
    includedItems = includedItems.filter((item) => {
      if (['game', 'dlc'].includes(item.type)) {
        if (item.preorder || item.newRelease) {
          return false;
        }
      }
      return true;
    });
  }

  const itemTypesObject = {};
  // Types
  includedItems.forEach((item) => {
    if (isStarDeal(item)) {
      itemTypesObject[item._id] = 'star_deal';
      return;
    }
    if (isPickAndMix(item)) {
      itemTypesObject[item._id] = 'pick_and_mix';
      return;
    }
    if (item.non_game_bundle) {
      itemTypesObject[item._id] = 'non_game_bundle';
      return;
    }
    itemTypesObject[item._id] = item.type;
  });

  const voucherApplyArray = [];
  const typesToCheck = [
    'star_deal',
    'pick_and_mix',
    'non_game_bundle',
    'dlc',
    'bundle',
    'game',
    'comic',
    'book',
    'software',
    'audio',
    'video',
  ];

  typesToCheck.forEach((type) => {
    if (voucher[type]) {
      voucherApplyArray.push(
        ...includedItems.filter(
          incItem => itemTypesObject[incItem._id] === type &&
            (!incItem.giveaway || incItem.tierIndex >= 1),
        ),
      );
    }
  });
  const voucherApplyIds = voucherApplyArray.map(voucherApp => voucherApp._id);
  const cartIds = cartItems.map(item => item._id);

  const voucherExcludes = difference(cartIds, voucherApplyIds);

  return {
    voucherApplyArray,
    voucherExcludes,
  };
}

const toFlatArray = (items) => {
  const arr = [];

  each(items, (item) => {
    times(item.quantity || 1, () => {
      arr.push(item);
    });
  });

  return arr;
};

const cartCalc = (cart, pickAndMixAll, voucherPassed) => {
  let rawtotal = 0;
  let price;
  let subtotal = 0;
  let total = 0;
  let fullprice = 0;
  let discountPrice = 0;
  let voucherTotal = 0;
  const cartClone = cloneDeep(cart);
  cartClone.items = toFlatArray(cartClone.items);
  const voucher = get(voucherPassed, 'voucher', {});
  const clonedPickAndMixAll = cloneDeep(pickAndMixAll);
  const cartPickAndMixes = [];

  let currencyCode = 'GBP';
  if (countryData.currencyCode) {
    currencyCode = countryData.currencyCode;
  }
  let taxRate = '20';
  if (countryData.taxRate) {
    taxRate = countryData.taxRate;
  }
  const cartTax = 1 + (taxRate / 100);
  cartClone.items.map((i) => {
    const item = i;
    item.out = {};

    if (isStarDeal(item)) item.out.isStarDeal = true;

    discountPrice = discountPriceCalc(item, currencyCode);
    if (item.type === 'bundle' && !item.is_srp_bundle) {
      price = get(item, `fullPrice.${currencyCode}`, tierPricing(item.tiers, currencyCode));
    } else {
      price = convertObjectToInt(item.price, currencyCode);
    }


    item.out.price = price;
    item.out.discountPrice = discountPrice;

    if (item.mystery) {
      item.out.price = discountPrice;
    }

    rawtotal += discountPrice;

    return i;
  });

  // Voucher includes excludeds
  total = 0;
  voucherTotal = 0;

  let voucherApplyArray;
  let voucherExcludes;

  if (voucher && voucher.code) {
    const itemsToApplyVoucherObject = {
      voucher,
      pickAndMixAll: clonedPickAndMixAll,
      cartItems: get(cartClone, 'items', []),
    };
    const voucherReturnObject = itemsToApplyVoucherTo(itemsToApplyVoucherObject);
    voucherApplyArray = voucherReturnObject.voucherApplyArray;
    voucherExcludes = voucherReturnObject.voucherExcludes;
  } else {
    voucherApplyArray = [];
    voucherExcludes = [];
  }

  if (Array.isArray(clonedPickAndMixAll) && clonedPickAndMixAll.length > 0) {
    const cartItems = cartClone.items;
    cartItems.sort(
      (a, b) => discountPriceCalc(b, currencyCode) - discountPriceCalc(a, currencyCode),
    );

    /**
    * Finds the highest pnm tier for the amount of items in basket. Then apply
    * the per item price from that tier to the new quantity
    *
    * @param  {Number} quantity       the amount of items for the pnm in the basket
    * @param  {Array.Object} tiers    all the tiers within a pick and mix
    * @return {Array.Object}          Single combined tier (in legacy format)
    *           {Number} quantity     new tier quantity
    *           {Number} baseQuantity original tier quantity
    *           {Object} price        new tier total price
    *           {Object} basePrice    original tier price
    */
    const getTierCombinations = (quantity, tiers) => {
      if (!quantity) {
        return [];
      }

      tiers.forEach((tier) => {
        tier.baseQuantity = tier.quantity; // eslint-disable-line no-param-reassign
        tier.basePrice = { ...tier.price }; // eslint-disable-line no-param-reassign
      });

      const highestActiveTier = orderBy(tiers, 'quantity', 'desc').find(t => quantity >= t.quantity);

      if (!highestActiveTier) {
        return [];
      }

      const adjustedTier = {
        ...highestActiveTier,
        quantity,
        price: mapValues(
          highestActiveTier.basePrice,
          v => ceil((v / highestActiveTier.baseQuantity) * quantity),
        ),
      };

      return [adjustedTier];
    };

    cartClone.pickAndMixTierCombinations = {};

    clonedPickAndMixAll.forEach((pickAndMix) => {
      const inBasket = [];
      const pickAndMixProductIds = pickAndMix.products.map(p => p._id);
      const tiers = get(pickAndMix, 'tiers', []);
      const itemMatches = cartItems.filter(item => pickAndMixProductIds.includes(item._id));

      if ((itemMatches && itemMatches.length < 1)) return;

      const tierCombinations = getTierCombinations(itemMatches.length, tiers);

      // Pick & Mix sent to cart for split test grouping
      const firstTier = get(tiers, '[0]');
      const pnmObj = {
        name: pickAndMix.name,
        slug: pickAndMix.slug,
        cover: pickAndMix.cover_image,
        groups: [firstTier],
        itemMatches,
        isUpsell: true,
        type: pickAndMix.type,
        tiers,
        valid_until: pickAndMix.valid_until,
        products: [],
        price: 0,
        quantity: pickAndMix.quantity,
        fullPrice: 0,
        tierPrice: 0,
      };

      cartClone.pickAndMixTierCombinations[pickAndMix._id] = tierCombinations;

      tierCombinations.forEach((tier) => {
        pnmObj.price = tier.price;

        const itemMatchesNotInBasket = itemMatches.filter(i => !inBasket.includes(i._id));
        itemMatchesNotInBasket.forEach((item, i) => {
          if ((i + 1) > tier.quantity) return;

          let priceDiff;
          let discountPriceDiff;

          const tierPrice = extractFromPriceObject(tier.price, currencyCode);
          const percentVoucher = get(voucher, 'percent', 0);
          const discountTierPrice = tierPrice * ((100 - percentVoucher) / 100);
          priceDiff = floor(tierPrice / tier.quantity);
          const remainder = round(tierPrice % tier.quantity);
          discountPriceDiff = floor(discountTierPrice / tier.quantity);
          const discountRemainder = round(discountTierPrice % tier.quantity);

          if ((i < remainder) && remainder) {
            priceDiff += 1;
          }

          if ((i < discountRemainder) && discountRemainder) {
            discountPriceDiff += 1;
          }

          inBasket.push(item._id);
          /* eslint-disable no-param-reassign */
          const roundTierPrice = Math.round(tierPrice);

          item.pickAndMix = {
            name: pickAndMix.name,
            slug: pickAndMix.slug,
            price: priceDiff / 100,
            // appliedDiscount: discountPriceDiff / 100,
            quantity: tier.quantity,
            baseQuantity: tier.baseQuantity,
            percent: tier.percent,
            promoPrice: convertObjectToInt(roundTierPrice, currencyCode) / 100,
            labelColour: pickAndMix.labelColour,
          };

          if (voucherApplyArray.map(vi => vi._id).includes(item._id)) {
            item.pickAndMix.appliedDiscount = discountPriceDiff / 100;
            pnmObj.tierPrice += (item.pickAndMix.appliedDiscount * 100);
          } else {
            pnmObj.tierPrice += (item.pickAndMix.price * 100);
          }

          item.out.discountPrice = priceDiff;
          pnmObj.fullPrice += item.out.price; // Combine full prices for price of p&m tier
        });

        // no longer need to upsell if there is a tier combination
        pnmObj.isUpsell = false;
      });

      cartPickAndMixes.push(pnmObj);
    });
  }

  cartClone.rawtotal = penceToPounds(rawtotal, currencyCode);


  let voucherSaving = 0;

  cartClone.items.forEach((item) => {
    total += item.out.discountPrice;
    fullprice += item.out.price;

    const voucherApplyArrayIds = voucherApplyArray.map(vi => vi._id);

    if (!isEmpty(voucher) && voucherApplyArrayIds.includes(item._id)) {
      let couponPrice = 0;
      let totalSavingPercent = 0;
      couponPrice = item.out.discountPrice > 0 ? item.out.discountPrice : price;
      couponPrice *= 1 - (voucher.percent / 100);
      couponPrice = Math.round(couponPrice);

      const actualPrice = (item.type === 'bundle' && !item.mystery) ? item.fullPrice[currencyCode] : item.price[currencyCode];

      totalSavingPercent = Math.round(((
        couponPrice - actualPrice) / actualPrice) * 100);
      /* eslint-disable no-param-reassign */
      item.out.couponPrice = couponPrice;
      item.out.totalSavingPercent = totalSavingPercent;
      /* eslint-enable no-param-reassign */
    }

    const voucherPrice = get(item, 'out.discountPrice', 0);
    let applyVoucher = false;

    voucherApplyArray.forEach((vapItem) => {
      if (item._id === vapItem._id) {
        voucherTotal += get(item, 'out.discountPrice', 0);
        applyVoucher = true;
      }
    });

    const typeOfVoucher = get(voucher, 'type_of_discount');
    if (applyVoucher) {
      if (!typeOfVoucher || typeOfVoucher === 'percent') {
        if (item.pickAndMix && item.quantity && voucher.percent > 0) {
          voucherSaving += round(100 * (item.pickAndMix.price - item.pickAndMix.appliedDiscount));
        } else {
          voucherSaving += round((voucher.percent) ? (voucherPrice) * (voucher.percent / 100) : 0);
        }
      }
    }
  });

  // group pick and mixes, then sort everything by recently added
  const addedOrder = orderBy(cartClone.items, ['added'], ['desc']); // needed to sort within pick and mix
  const grouped = groupBy(addedOrder, 'pickAndMix.slug');
  const merged = [...(grouped.undefined || []), ...Object.keys(grouped).filter(k => k !== 'undefined').map(k => grouped[k])];
  const sortedMerged = orderBy(merged, [g => get(g, '[0].added', get(g, 'added', 0))], ['desc']);
  const flattened = sortedMerged.map(i => (Array.isArray(i) ? i : [i])).flat();

  cartClone.items = flattened;

  const typeVouch = get(voucher, 'type_of_discount');
  if (typeVouch === 'money') {
    const voucherAmountObject = get(voucher, 'amount');
    const voucherAmount = voucherAmountObject[currencyCode];
    voucherSaving = Math.min(voucherTotal, voucherAmount * 100);
  }

  total = Math.round(total * 100) / 100;

  subtotal = round((total / cartTax) * 100) / 100;

  cartClone.fullprice = penceToPounds(fullprice);
  cartClone.subtotal = penceToPounds(subtotal);
  cartClone.vat = penceToPounds(total - subtotal);
  cartClone.grandTotal = penceToPounds(total);
  cartClone.voucherSaving = penceToPounds(voucherSaving);
  cartClone.total = penceToPounds(Math.max(total - voucherSaving, 0));
  cartClone.saving = penceToPounds(fullprice - total);
  cartClone.promoSaving = penceToPounds(rawtotal - total);
  cartClone.bonusSaving = 0;
  cartClone.voucherExcludes = voucherExcludes;


  if (!isEmpty(cartPickAndMixes)) {
    cartClone.pickAndMixes = cartPickAndMixes;
  }

  cartClone.currencyCode = currencyCode;

  let currencySymbol = '£';
  switch (currencyCode) {
    case 'GBP':
      currencySymbol = '£';
      break;
    case 'USD':
    case 'CAD':
    case 'AUD':
      currencySymbol = '$';
      break;
    case 'EUR':
      currencySymbol = '€';
      break;
    case 'JPY':
      currencySymbol = '¥';
      break;
    case 'RUB':
      currencySymbol = 'RUB ';
      break;
    default:
      currencySymbol = '£';
  }

  cartClone.checkoutMessageObject = checkoutError();

  if (voucher.min_spend && total < voucher.min_spend[currencyCode] && cartClone.items.length) {
    const minSpendMessage = `You must spend over ${currencySymbol}${penceToPounds(voucher.min_spend[currencyCode])} to use this discount code`;
    cartClone.checkoutMessageObject = checkoutError('checkout', 'SPEND_LIMIT_REACHED', minSpendMessage);
  }

  if (voucher.max_spend && voucher.max_spend[currencyCode]
    && total > voucher.max_spend[currencyCode] && cartClone.items.length) {
    const maxSpendMessage = `You must spend less than ${currencySymbol}${penceToPounds(voucher.max_spend[currencyCode])} to use this discount code`;
    cartClone.checkoutMessageObject = checkoutError('checkout', 'SPEND_LIMIT_REACHED', maxSpendMessage);
  }

  return cartClone;
};

export default createSelector(
  cartSelector,
  pickAndMixAllSelector,
  voucherSelector,
  cartCalc,
);

import anonymous from 'Redux/reducers/anonymous';
import auth from 'Redux/reducers/auth';
import adverts from 'Redux/reducers/adverts';
import cart from 'Redux/reducers/cart';
import checkout from 'Redux/reducers/checkout';
import modal from 'Redux/reducers/modal';
import feedbackPopups from 'Redux/reducers/feedback-popups';
import orders from 'Redux/reducers/orders';
import pickAndMix from 'Redux/reducers/pickandmix';
import redeem from 'Redux/reducers/redeem';
import redeemError from 'Redux/reducers/redeem-error';
import ageGating from 'Redux/reducers/age-gating';
import emailSubscribe from 'Redux/reducers/email-subscribe';
import voucher from 'Redux/reducers/voucher';
import vouchers from 'Redux/reducers/vouchers';
import bundleReminder from 'Redux/reducers/bundle-reminders';
import facebook from 'Redux/reducers/facebook';
import fandom from 'Redux/reducers/fandom';
import initial from 'Redux/reducers/initial-load';
import accountConfirm from 'Redux/reducers/account-confirm';
import redeemConfirm from 'Redux/reducers/redeem-confirm';
import passwordReset from 'Redux/reducers/password-reset';
import nativeRates from 'Redux/reducers/native-rates';
import recaptcha from 'Redux/reducers/recaptcha';
import errorMessages from 'Redux/reducers/error-messages';
import recommendations from 'Redux/reducers/recommendations';
import splitTest from 'Redux/reducers/split-test';
import megaMenu from 'Redux/reducers/mega-menu';
import universalError from 'Redux/reducers/universal-error';
import wishlist from 'Redux/reducers/wishlist';
import notificationBanner from 'Redux/reducers/notification-banner';
import loginActions from 'Redux/reducers/login-actions';
import atok from 'Redux/reducers/atok';
import ubisoftError from 'Redux/reducers/ubisoft-error';
import bundleBlast from 'Redux/reducers/bundle-blast';
import language from 'Redux/reducers/languages';
import recentlyViewed from 'Redux/reducers/recently-viewed';
import opportunities from 'Redux/reducers/opportunities';
import flashSale from 'Redux/reducers/flash-sale';
import checkoutMessage from 'Redux/reducers/checkout-message';
import voucherMessage from 'Redux/reducers/voucher-message';
import cartMessage from 'Redux/reducers/cart-message';
import unsubsribeMessage from 'Redux/reducers/unsubscribe-message';
import optinMessage from 'Redux/reducers/optin-message';
import deliveries from 'Redux/reducers/deliveries';
import trustpilot from 'Redux/reducers/trustpilot';
import mystery from 'Redux/reducers/mystery';
import apipayment from 'Redux/reducers/apipayment';
import twoFactor from 'Redux/reducers/two-factor';
import publishers from 'Redux/reducers/publishers';
import sales from 'Redux/reducers/sales';
import freeProduct from 'Redux/reducers/free-product';
import exitIntentState from 'Redux/reducers/exit-intent';
import deviceFingerprint from 'Redux/reducers/device-fingerprint';
import cookie from 'Redux/reducers/cookie';
import starDeal from 'Redux/reducers/star-deal';
import google from 'Redux/reducers/google';
import selectableFreeProducts from 'Redux/reducers/selectable-free-products';
import siteTheme from 'Redux/reducers/site-theme';
import siteBrand from 'Redux/reducers/site-brand';
import accountPreference from 'Redux/reducers/account-preference-centre';
import gift from 'Redux/reducers/gift';
import epic from 'Redux/reducers/epic';
import franchises from 'Redux/reducers/franchises';
import liveDisplayTypes from 'Redux/reducers/live-display-types';
import userTraits from 'Redux/reducers/user-traits';
import userLibrary from 'Redux/reducers/user-library';
import steamLibrary from 'Redux/reducers/steam-library';
import notifications from 'Redux/reducers/notifications';
import checkoutEmailSignUp from 'Redux/reducers/checkout-email-signup';
import competitions from 'Redux/reducers/competitions';
import competitionEntry from 'Redux/reducers/competition-entry';

export default {
  accountPreference,
  adverts,
  ageGating,
  anonymous,
  apipayment,
  atok,
  auth,
  bundleBlast,
  bundleReminder,
  cart,
  cartMessage,
  checkout,
  checkoutEmailSignUp,
  checkoutMessage,
  competitionEntry,
  competitions,
  confirm: accountConfirm,
  cookie,
  deliveries,
  deviceFingerprint,
  emailSubscribe,
  epicURL: epic,
  errorMessages,
  exitIntentState,
  facebook,
  fandom,
  feedbackPopups,
  flashSale,
  franchises,
  freeProduct,
  gift,
  google,
  initial,
  language,
  liveDisplayTypes,
  loginActions,
  megaMenu,
  modal,
  mystery,
  nativeRates,
  notificationBanner,
  notifications,
  opportunities,
  optin: optinMessage,
  orders,
  password_reset: passwordReset,
  pickAndMix,
  publishers,
  recaptcha,
  recentlyViewed,
  recommendations,
  redeem,
  redeemConfirmStatus: redeemConfirm,
  redeemError,
  sales,
  selectableFreeProducts,
  siteBrand,
  siteTheme,
  splitTest,
  starDeal,
  steamLibrary,
  trustpilot,
  twoFactor,
  ubisoftError,
  universalError,
  unsubscribe: unsubsribeMessage,
  userLibrary,
  userTraits,
  voucher,
  voucherMessage,
  vouchers,
  wishlist,
};
